
<template>
   <div>
      <div class="flex items-center w-full p-4 bg-primary text-white top-header-page" @click.stop="back()">
         <feather-icon icon="ChevronLeftIcon"  class="cursor-pointer mr-4" @click.stop="back()"></feather-icon>
         <h4 class="text-white">Kembali</h4>
      </div>
      <vx-card class="mb-5">
        <div>
          
          <div class="post-header mb-4">
              <div class="ml-4">
                <h3>{{ $route.params.post.task_title }}</h3>
              </div>
              <div class="flex justify-end items-center">
                <div class="ml-4">
                  <p>{{ $route.params.post.tanggal }}</p>
                </div>
              </div>
          </div>
          <div class="post-media-container mb-6 mt-4">
            <ul class="flex post-media-list">
              <img class="responsive rounded" :src="getImage('/post/'+$route.params.post.thumbnail)" :alt="$route.params.post.task_title">
            </ul>
          </div>
          <div class="post-content">
            <p v-html="$route.params.post.content"></p>
          </div>

        </div>
      </vx-card>
   </div>
</template>

<script>

export default {
   data () {
      return {
      }
   },
   components: {
   },
   computed: {
   },
   created () {
      this.$store.commit('navbarUpdateStatus', true)
   },
   methods: {
      back () {
         const kategori = this.$route.params.kategori
         this.$router.replace({ 
            name: 'post',
            params: { kategori } 
         });
      },
   }
}
</script>

<style scoped>
</style>
